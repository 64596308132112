/* eslint-disable vue/valid-v-bind */
<template>
  <tr class="w-full hover:bg-grey-lightest my-1 border-b">
    <div class="sizeMin flex items-center justify-center m-2">
      <a href="" @click.prevent="editPartner(partner.id)">
        <font-awesome-icon
          class="text-black text-base hover:bg-grey"
          :icon="['fas', 'edit']"
        ></font-awesome-icon>
      </a>
    </div>
    <td class="mr-auto" v-text="partner.name"></td>
    <td v-text="partner.email"></td>
    <td class="custStyle" colspan="5">
      <div :class="{ maxH: partner.cities.length > 3 }">
        <div
          class="w-full flex"
          :class="{
            'border-black border-b border-grey-light':
              partner.cities.length !== 1,
          }"
          v-for="item in partner.cities"
          :key="item.id"
        >
          <div class="citySizee border-r p-2">
            {{ item.city }}
          </div>
          <div class="citySizee flex flex-col border-r p-2">
            <div v-for="it in item.products" :key="it.id">{{ it.name }}</div>
          </div>
          <div class="citySizee border-r p-2">
            <select :value="item.status" @change="changePartnerStatus(item, $event.target.value)" class="w-full p-1 border border-brand-light rounded">
              <option value="active" class="bg-brand rounded text-white hover:bg-brand-dark">active</option>
              <option value="inactive" class="bg-brand rounded text-white hover:bg-brand-dark">inactive</option>
              <option value="inert" class="bg-brand rounded text-white hover:bg-brand-dark">inert</option>
              <option value="statInactive" class="bg-brand rounded text-white hover:bg-brand-dark">statInactive</option>
              <option value="manual_inactive" class="bg-brand rounded text-white hover:bg-brand-dark">manual_inactive</option>
              <option value="financialInactive" class="bg-brand rounded text-white hover:bg-brand-dark">financialInactive</option>
              <option value="blocked" class="bg-brand rounded text-white hover:bg-brand-dark">blocked</option>
            </select>
          </div>
          <div style="width: 130px" class="p-2 border-r">
              <v-date-picker :value="item.has_excuse_until ? new Date(item.has_excuse_until) : null"
                             @input="onChangeHasExcuse($event, item)"
                             :min-date="new Date()"
                             class="reason-for-day no-underline rounded shadow appearance-none border border-grey p-2 text-grey-darker"
                             show-caps>
              </v-date-picker>
          </div>
          <div style="width: 220px"  class="border-r flex-1">
            <textarea class="appearance-none w-full h-full min-h-full" @input="changePartnerComment(item.id, $event)" :value="item.comment" />
          </div>
        </div>
      </div>
    </td>

    <portal :to="`edit-partner-${partner.id}`">
      <partner-create
        :partner-id="partner.id"
        :partner-info="partner"
        @created="$emit('updated')"
      ></partner-create>
    </portal>
  </tr>
</template>

<script>
import axios from 'axios'
import PartnerCreate from './create'
import debounce from 'lodash/debounce'
import moment from 'moment/moment'
import parse from 'date-fns/parse'

export default {
  props: ['partner', 'checked'],
  components: { PartnerCreate },
  methods: {
    parse,
    changePartnerStatus (prop, status) {
      prop.status = status
      if (status === 'active') {
        prop.is_punished = false
      } else prop.is_punished = true
      axios
        .post(`/api/partners/${prop.id}/change-punishment-status?is_punished=${prop.is_punished}&status=${prop.status}`)
        .then(() => {
          if (status !== 'active') {
            prop.has_excuse_until = null
          }
        })
      // eslint-disable-next-line handle-callback-err
        .catch((error) => {
          alert(error)
        })
    },
    changePartnerComment: debounce(function (id, event) {
      let data = new FormData()
      data.append('comment', event.target.value)
      axios
        .post(`/api/partners/${id}/change-comment`, data)
        .catch((error) => {
          alert(error)
        })
    }, 500),
    editPartner (id) {
      this.modalHolder.open(
        `edit-partner-${id}`,
        'Редактирование информации о партнере',
        'partner-modal',
        'false'
      )
    },
    onChangeHasExcuse ($event, item) {
      if (!$event) return
      item.has_excuse_until = moment($event).format('YYYY-MM-DD')
      axios
        .post(`/api/partners/${item.id}/change-has-excuse`, {
          has_excuse_until: moment($event).format('YYYY-MM-DD'),
        }).then(() => {
          item.status = 'active'
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
@tailwind utilities;
@tailwind utilities;

.reason-for-day >>> input {
    width: 100px
}
.citySizee {
  width: 135px !important;
}
.custStyle {
  padding: 0 !important;
}
.sizeMin {
  width: 40px !important;
}
.maxH {
  max-height: 250px;
  overflow-y: scroll;
}
td {
  vertical-align: top;
}
/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #b8c2cc;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #8795a1;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #6a6384;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
td {
  @apply .text-left .p-2 .bg-white .font-sans .text-sm .border-b .border-r .border-l .border-grey-light;
}
</style>
